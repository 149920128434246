.contact {
    margin-top: 60px;
    padding: 0 10%;
    color: white;
}

.contact h2{
    font-size: 2.5rem;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-bottom: 3px rgb(255, 255, 255) dashed;
    padding-bottom: 10px;
    margin-right: 50%;
    filter: drop-shadow(0 0px 5px rgba(255, 255, 255, 0.19));
    margin-bottom: 60px;
}

.contact-info{
    padding-bottom: 60px;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.contact-form{
    display: flex;
    flex-direction: column;
    width: 80%;
    font-size: 1.125rem;
    margin-bottom: 60px;
}

.input-info{
    border-radius: 8px;
    border: 0;
    height: 45px;
    padding-left: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    letter-spacing: 2px;

    background-color: rgba(250, 240, 255, 0.2);
    color: rgb(255, 255, 255);
}

.input-info::placeholder{
    color: rgba(255, 255, 255, 0.5);
}

.contact-form label{
    margin-bottom: 5px;
}

.message-info{
    width: 100%;
    height: 30vh;
    resize: none;

    border-radius: 8px;
    border: 0;

    font-size: 1rem;
    letter-spacing: 2px;


    background-color: rgba(250, 240, 255, 0.2);
    color: rgb(255, 255, 255);

    padding: 5px 10px;
    margin-bottom: 15px;
    margin-bottom: 10px;
}

.message-info::placeholder{
    color: rgba(255, 255, 255, 0.5);
}

.message-counter{
    text-align: right;
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.1rem;  
}


.send-email-btn{

    margin-top: 20px;
    margin-left: 25%;
    margin-right: 25%;

    border: 1px solid rgba(255,255,255, 0.255);
    padding: 12px 24px;
    border-radius: 19px;
    font-size: 1rem;  
    font-weight: bold;
    letter-spacing: 2px;  
    cursor: pointer;

    background-color: rgba(250, 240, 255, 0.2);
    color: rgba(255,255,255,0.95);
    filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
    transition: all .2s ease; 
}

.send-email-btn:hover{
    transform: scale(1.12);  
    border-color: rgba(255, 255, 255, 0.9);
    transition: all .25s ease;    
}

.social-icons {
    display: flex;

    width: 80%;
    justify-content: space-between;
}

.social-icons i {
    font-size: 2rem;
    color: rgba(250, 240, 255, 0.566);
    filter: drop-shadow(0 10px 2px rgba(0,0,0,0.125));
    transition: all .15s ease; 
    cursor: pointer;
}

.social-icons i:hover{
    color: white;
    transform: scale(1.125);  
    transition: all .2s ease;    
}


@media screen and (max-width:1100px) {
    .contact{
        padding: 0 5%;
    }

    .contact-form{
        width: 90%;
    }
}

@media screen and (max-width:570px) {
    .contact-form{
        width: 95%;
    }

    .send-email-btn{
        margin-top: 35px;
        margin-left: 6%;
        margin-right: 6%;
    }

    .social-icons {
        width: 95%;
    }

    .social-icons i {
        font-size: 1.8rem;
    }
}