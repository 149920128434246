:root{
    --main-header-bg-color: rgba(38, 37, 43, 0.8); 
    --hover-header-bg-color: rgba(97, 88, 105, 0.537);
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

  
header {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    background: var(--main-header-bg-color);
    padding: 1% 4%;
    align-items: center;
    height: 61px;
    z-index: 1100;

    transition-timing-function: ease-in;
    transition: 0.3s;
}

.hide-navbar{
    transform: translate(0px, -61px);
}

#navbar {
    display: flex;
}

.navlink {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-decoration: none;
    padding: 12px 24px;
    margin: 0 2px;
    position: relative;
    background: var(--transparent-color);
    transition: 0.1s ease-in-out;
}

.navlink:hover,
.active{
    background: var(--hover-header-bg-color);
}

.navlink:hover::after,
.active::after{
    content: "";
    width: 30%;
    height: 2px;
    background-color:var(--main-header-bg-color);
    position: absolute;
    bottom: 5px;
    left: 20px;
}

#mobile {
    display: none;
}


#mobile i{
    color: white;
}

@media screen and (max-width:570px) {
    #navbar {
        flex-direction: column;
        position: fixed;
        justify-content: flex-start;
        background-color: var(--main-header-bg-color);

        top: 61px;
        right: -300px;
        width: 300px;
        height: 100vh;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 10px 0 10px;

        transition: 0.25s ease-in-out;
    }

    #navbar.open{
        right: 0px;
    }

    #mobile {
        display: block;
    }

    .navlink{
        font-size: 18px;
        margin: 0 0 20px 0;
        padding: 16px 168px 16px 24px;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }
    
}

/* Bubble */

.logo-container:hover{
    cursor: pointer;
}

.logo-container {
    position: relative;
    display: inline-block;
  }
  
  .bubble {
    position: absolute;
    top: calc(100% - 50px);
    left: calc(100% + 10px);
    transform: translateY(0%);
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    min-width: 150px; /* Limita el ancho máximo del bubble */
    word-wrap: break-word;
    transform-origin: top center; /* Cambio de transform-origin */
    z-index: 1;
  }
  
  .bubble span {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
    color: #333;
    font-weight: 450; /* Ajusta el valor para aumentar el grosor */
  }