.portfolio {
    margin-top: 60px;
    padding: 0 10%;
    color: white;
}

.portfolio h2{
    margin-bottom: 60px;
    font-size: 2.5rem;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-bottom: 3px rgb(255, 255, 255) dashed;
    padding-bottom: 10px;
    margin-right: 50%;
    filter: drop-shadow(0 0px 5px rgba(255, 255, 255, 0.19));
}

@media screen and (max-width:560px) {
    .portfolio {
        margin-top: 40px;
    }

    .portfolio h2{
        margin-bottom: 40px;
    }
}