/* Image Container */

.skill-container {
    display: grid;
    place-items: center;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
}

.skill-item{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(250, 240, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.125);  
    z-index: 0;
    width: 170px;
    height: 170px;
    margin: 0px 50px;
    margin-bottom: 35px;
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(146, 51, 130, 0.1);
}

.skill-item img {
    width: 90px;
    z-index: 1;
    filter: drop-shadow(0 0px 4px rgba(228, 184, 225, 0.25));
}  


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    border-radius: 12px;
    transition: opacity 0.3s ease;
    z-index: 2;
}

.skill-item:hover .overlay,
.skill-item img:hover .overlay {
    opacity: 1;
}
  
.overlay p {
    color: #fff;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 10px;
}
  
.rating {
    display: flex;
    justify-content: center;
}
  
.star {
    display: inline-block;
    color: #ffd700;
    font-size: 19px;
    margin: 0 2px;
    filter: drop-shadow(0 0px 5px rgba(251, 220, 60, 0.4));
}

.star_empty {
    color: #b6b6b6;
    filter: drop-shadow(0 0px 5px rgba(251, 220, 60, 0));
}


@media screen and (max-width:1100px) {
    .skill-item{
        width: 130px;
        height: 130px;
        margin-left: 35px;
        margin-right: 35px;
    }
    
    .skill-item img {
        width: 75px;
    }

    .star {
        font-size: 15px;
        margin: 0 2px;
    }
}


@media screen and (max-width:860px){
    .skill-item{
        margin: 0px 20px;
        margin-bottom: 35px;
    }
}

@media screen and (max-width:745px){
    
    .skill-item{
        width: 100px;
        height: 100px;
        margin-left: 15px;
        margin-right: 15px;
    }
    
    .skill-item img {
        width: 60px;
    }

    .star {
        font-size: 12px;
        margin: 0 1.5px;
    }

    .overlay p {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 10px;
    }
}


@media screen and (max-width:560px){
    .skill-item{
        width: 90px;
        height: 90px;
        margin-left: 10px;
        margin-right: 10px;
    }
}


@media screen and (max-width:460px){
    .skill-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .about-top p{
        letter-spacing: 0px;
    }
    
    .skill-item{
        width: 85px;
        height: 85px;
        margin-left: 8px;
        margin-right: 8px;
    }
    
    .skill-item img {
        width: 55px;
    }

}