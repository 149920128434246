.about {
    margin-top: 40px;
    padding: 0 10%;
}

.about-top{
    height: 100%;
    padding: 10px;
    color: white;
    margin-bottom: 70px;
}

.about-top h2{
    margin-bottom: 60px;
    font-size: 2.5rem;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-bottom: 3px rgb(255, 255, 255) dashed;
    padding-bottom: 10px;
    margin-right: 50%;
    filter: drop-shadow(0 0px 5px rgba(255, 255, 255, 0.19));
}

.about-top p{
    padding-left: 8%;
    font-size: 1.125rem;
    letter-spacing: 2px;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0 0px 6px rgba(255, 255, 255, 0.2));
}

.about-profile{
    display: flex;
    align-items: center;
}

.about-profile img {
    max-width: 230px;
    border-radius: 100%;
    box-shadow: 5px 15px 3px rgba(0, 0, 0, 0.1);
}

.about-bottom{
    display: flex;
    justify-content: center;
}


@media screen and (max-width:1100px) {
    .about{
        padding: 0 5%;
    }

    .about-profile{
        flex-direction: column;
    }

    .about-top h2{
        margin-bottom: 40px;
    }

    .about-profile img {
        margin-bottom: 40px;
    }
    
    .about-top p {
        text-align: center;
        padding: 0 4%;
    }

    .about-top{
        margin-bottom: 40px;
    }
}

@media screen and (max-width:745px){
    .about-profile img {
        max-width: 180px;
    }
}