
.portfolioCard {
    backdrop-filter: blur(16px) saturate(120%);
    -webkit-backdrop-filter: blur(16px) saturate(120%);
    background-color: rgba(253, 219, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);  
    padding: 18px 18px 18px 18px;  
    filter: drop-shadow(0 30px 10px rgba(0,0,0,0.125));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    text-align: center;
    margin-left: 2px;

    max-width: 400px;
}

.wrapper img{
    background-position: center;
    background-size: cover;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(255,255,255, 0.255);
    margin-bottom: 19px;
}

.wrapper img{
    -drag: none;
    user-select: none;
    -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}

.wrapper h1{
    color: rgba(255,255,255,0.98);
    text-transform: uppercase;
    font-size: 1.8rem;
    margin-bottom: 8px;
}

.wrapper p {
    color: #fff;
    text-align: center;
    font-size: 0.8rem;
    line-height: 150%;
    letter-spacing: 2px;
    margin-bottom: 19px;
}

.btn-fill {
    border: none;
    padding: 12px 24px;
    border-radius: 19px;
    font-size: 0.8rem;  
    letter-spacing: 2px;  
    cursor: pointer;

    background-color: rgba(250, 240, 255, 0.2);
    color: rgba(255,255,255,0.95);
    font-weight: bold;
    transition: all .3s ease; 
}
  
.btn-fill:hover{
    transform: scale(1.125);  
    border-color: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
    transition: all .3s ease;    
}

.react-multi-carousel-list{
    position: unset !important;
}


@media screen and (max-width: 1570px){
    .portfolioCard {
        max-width: 315px;
    }

}

@media screen and (max-width: 1250px){
    .portfolioCard {
        max-width: 400px;
        margin-left: 0px;
    }

}


@media screen and (max-width: 1100px){
    .portfolio {
        padding: 0 5%;
    }
}


@media screen and (max-width: 930px){
    .portfolioCard {
        max-width: 315px;
    }

}


@media screen and (max-width: 745px){
    .portfolioCard {
        max-width: 280px;
    }
}


@media screen and (max-width: 675px){
    .portfolioCard {
        max-width: 220px;
    }
}

@media screen and (max-width: 520px){
    .portfolioCard {
        max-width: 400px;
    }
}