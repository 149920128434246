.portfolioPage{
    align-items: center;
    justify-content: center;
    display: flex;
}

.project-contenetor{
    color: white;
    min-height: 100vh;
    max-width: 1000px;
    width: 1000px;
    margin-top: 61px;
    padding: 20px;

    background-color: rgba(250, 240, 255, 0.1);
    
}

.page-button {
    margin-right: 12px;
    margin-bottom: 12px;
}

.project-contenetor h2{
    font-size: 2.5rem;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-bottom: 3px rgb(255, 255, 255) dashed;
    padding-bottom: 10px;
    margin-right: 40%;
    filter: drop-shadow(0 0px 5px rgba(255, 255, 255, 0.19));
    margin-bottom: 20px;
}

.project-columns{
    display: flex;
    width: 100%;
    margin-top: 8px;
}

.left-project{
    display: block;
    width: 347px;
}

.left-project .responsive-video{
    margin-bottom: 10px;
    aspect-ratio: 16 / 9;
    width: 100%;
}

@supports not (aspect-ratio: 1) {
    .left-project .responsive-video {
      position: relative;
      padding-bottom: 56.25%;
    }
  
    .left-project .responsive-video iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }


.left-project .responsive-video iframe{
    width: 100%;
    height: 100%;
    border: 0;
}

.left-project img{
    max-width: 100%;
    margin-bottom: 10px;
    object-fit: cover;
}

.right-project{
    flex: 1;
    width: 0;
    min-width: 0;
    margin: 30px;
    margin-top: 0;

    font-size: 1rem;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0 0px 6px rgba(255, 255, 255, 0.13));
    letter-spacing: 2px;
}

.right-project p{
    margin-top: 20px;
    white-space: pre-wrap;
}

.left-cell{
    padding: 2.5px 5px;
}

.right-cell{
    padding-left: 10px;
}

@media screen and (max-width:1025px) {

    .project-contenetor{
        max-width: 600px;
        width: 100%;
    }

    .project-columns{
        display: block;
    }

    .left-project, .right-project{
        width: 100%;
    }

    .right-project{
        margin: 0;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .left-project img{
        height: 200px;
    }

    .react-multi-carousel-item{
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width:650px) {
    .project-contenetor{
        background-color: rgba(240, 248, 255, 0);
    }
}