.banner {
    width: 100%;
    display: flex;
    min-height: 60vh;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-position: center;

    color: white;
}

.banner h1{
    font-size: 3.2rem;
    margin: 32px 0;
    letter-spacing: 4px;
    text-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.banner p{
    font-size: 1.1rem;
    letter-spacing: 2px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 1);
    text-align: center;    
}

@media screen and (max-width:360px){
    .banner h1{
        font-size: 2.3rem;
        letter-spacing: 3px;
        margin: 16px 0;
    }

    .banner p{
        font-size: 1rem;  
    }

}